import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { getContainers, getCrop, searchCrops } from 'api/featurestore';
import { fetchImagesQueued } from '../services/fetchImagesQueued';

const FeatureStoreContext = React.createContext();
export const useFeatureStoreContext = () =>
  React.useContext(FeatureStoreContext);

class FeatureStoreContextProvider extends Component {
  PAGE_SIZE = 200;

  constructor(props) {
    super(props);
    this.state = {
      labels: [],
      label: 'box',
      containers: [],
      container: '',
      isLoading: false,
      error: false,
      notFound: false,
      offset: 0,
      isDisabledLoadMore: false,
      failedCrops: []
    };
  }

  loadContainers = async () => {
    const response = await getContainers();
    if (response.status === 200) {
      await response.json().then((data) =>
        this.setState((prev) => ({
          ...prev,
          containers: data.results,
          notFound: data.results.length === 0,
          error: false,
          isLoading: false,
          labels: [],
          failedCrops: []
        }))
      );
    } else {
      this.setState((prev) => ({
        ...prev,
        containers: [],
        notFound: false,
        error: true,
        isLoading: false,
        labels: [],
        failedCrops: []
      }));
    }
  };

  loadCrops = async (data) => {
    const cropErr = [];

    const apiCall = (crop) => {
      return getCrop(crop.description.properties.featurestore_id, {
        photo_id: crop.description.properties.photo_id,
        centroid_scale: 1.0,
        photo_as_url: true
      });
    };

    const onImageLoaded = (response, crop) => {
      crop.selected = false;
      crop.source = response;
      this.setState((prev) => {
        const { labels } = prev;
        labels.push(crop);
        return { ...prev, labels };
      });
    };

    const onImageLoadFailed = (crop) => {
      cropErr.push(crop);
    };

    const getImgUrl = (data) => data.crop;

    const {result} = fetchImagesQueued(
      data,
      apiCall,
      onImageLoaded,
      onImageLoadFailed,
      getImgUrl
    );

    await result;
    return cropErr;
  };

  reloadCrops = async () => {
    const { failedCrops } = this.state;
    this.setState((prev) => ({
      ...prev,
      isLoading: true,
      failedCrops: []
    }));

    const cropErr = await this.loadCrops(failedCrops);
    this.setState((prev) => ({
      ...prev,
      isLoading: false,
      failedCrops: [...cropErr]
    }));
  };

  searchLabel = async (offset = 0) => {
    const { container, label } = this.state;

    this.setState((prev) => {
      const labels = offset === 0 ? [] : prev.labels;
      const failedCrops = offset === 0 ? [] : prev.failedCrops;
      return {
        ...prev,
        error: false,
        notFound: false,
        isLoading: true,
        isDisabledLoadMore: false,
        labels,
        failedCrops,
        offset
      };
    });

    const response = await searchCrops({
      container,
      label_names: label,
      limit: this.PAGE_SIZE + 1,
      offset
    });

    if (response.status === 200) {
      const data = await response.json();
      if (data.results.length) {
        const dataResults = data.results;
        if (data.results.length <= this.PAGE_SIZE) {
          this.setState((prev) => ({ ...prev, isDisabledLoadMore: true }));
        } else {
          dataResults.splice(-1, 1);
        }
        const cropErr = await this.loadCrops(dataResults);
        this.setState((prev) => {
          const { failedCrops } = prev;
          const newFailedCrops = [...failedCrops, ...cropErr];
          const isLoading = false;
          return { ...prev, isLoading, failedCrops: newFailedCrops };
        });
      } else {
        this.setState((prev) => ({
          ...prev,
          notFound: true,
          isLoading: false,
          isDisabledLoadMore: true
        }));
      }
    } else {
      this.setState((prev) => ({
        ...prev,
        error: true,
        isLoading: false,
        isDisabledLoadMore: true
      }));
    }
  };

  showMoreLabels = () => {
    const { offset } = this.state;
    this.searchLabel(offset + this.PAGE_SIZE);
  };

  setField = (key, value) =>
    this.setState((prev) => ({
      ...prev,
      [key]: value,
      labels: [],
      failedCrops: []
    }));

  render() {
    const { children } = this.props;
    return (
      <FeatureStoreContext.Provider
        value={{
          ...this.state,
          searchLabel: this.searchLabel,
          setField: this.setField,
          loadContainers: this.loadContainers,
          showMoreLabels: this.showMoreLabels,
          reloadCrops: this.reloadCrops
        }}
      >
        {children}
      </FeatureStoreContext.Provider>
    );
  }
}

FeatureStoreContextProvider.propTypes = {
  children: PropTypes.node
};

FeatureStoreContextProvider.defaultProps = {
  children: null
};

export default FeatureStoreContextProvider;
