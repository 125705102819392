/* eslint react/no-array-index-key: "off" */

import React from 'react';
import { Col, Spinner } from 'react-bootstrap';
import { useFeatureStoreContext } from 'contexts/FeatureStore';
import SpinnerButton from 'components/Buttons/SpinnerButton';
import CropImage from 'components/Images/CropImageSVG';
import CreateCropJobModal from 'modals/CreateCropJobModal';
import CropDetailsModal from 'modals/CropDetailsModal';
import ArrayDropdown from 'components/Buttons/ArrayDropdown';

function SearchInput() {
  const { searchLabel, isLoading, container, setField, label } =
    useFeatureStoreContext();
  return (
    <div className="form-inline col-12">
      <div className="form-group align-items-center row mb-0">
        <label htmlFor="label" className="col-3">
          Label:&nbsp;
        </label>
        <input
          disabled={isLoading}
          type="search"
          onChange={(event) => setField('label', event.target.value)}
          className="form-control col-6"
          name="label"
          value={label}
        />
        <span className="col-3">
          <SpinnerButton
            disabled={label === '' || !container || isLoading}
            onClick={() => searchLabel()}
            isLoading={isLoading}
            value="Search"
          />
        </span>
      </div>
    </div>
  );
}

function CreateCropJobs() {
  const {
    containers,
    labels,
    container,
    label,
    error,
    setField,
    notFound,
    loadContainers,
    showMoreLabels,
    isLoading,
    isDisabledLoadMore,
    failedCrops,
    reloadCrops
  } = useFeatureStoreContext();

  const [modalState, setModalState] = React.useState({
    createCrop: false,
    detailsCrop: false,
    detailsCropIndex: 0
  });
  const [options, setOptions] = React.useState([]);

  React.useEffect(() => {
    loadContainers();
  }, []);

  React.useEffect(() => {
    if (containers.length) {
      const options = containers.reduce((acc, currItem) => {
        acc.push({ label: currItem.name, value: currItem.name });
        return acc;
      }, []);
      setOptions(options);
    }
  }, [containers]);

  return (
    <div>
      {error && (
        <div className="alert alert-danger">
          <strong>
            Sorry, an unexpected error occurred, during fetching data.
          </strong>
        </div>
      )}
      {notFound && (
        <div className="alert alert-warning" role="alert">
          Not found. Please try different Label or Container name.
        </div>
      )}
      <div className="container">
        <div className="row align-items-center ">
          <div className="col-12 col-lg-5 col-xl-3 item">
            <ArrayDropdown
              disabled={isLoading}
              list={options}
              onClick={(source) => setField('container', source)}
              defaultValue="Containers"
            />
          </div>
          <div className="col-12 mt-1 col-lg-7 mt-lg-0 col-xl-6 item">
            <SearchInput />
          </div>
          <div className="col-12 mt-1 col-lg-7 col-xl-3 mt-xl-0">
            <button
              type="button"
              id="createCropButton"
              disabled={labels.length === 0}
              onClick={() =>
                setModalState((prev) => ({ ...prev, createCrop: true }))
              }
              className="btn btn-fill ml-3"
            >
              Create Crop Jobs
            </button>
          </div>
        </div>
      </div>
      <div className="row mt-3">
        <div className="d-flex flex-wrap">
          {labels.map((item, index) => (
            <div
              key={index}
              className="p-2 "
              onClick={() =>
                setModalState((prev) => ({
                  ...prev,
                  detailsCrop: true,
                  detailsCropIndex: index
                }))
              }
            >
              <div style={{ position: 'relative' }} className="maintxt">
                <span
                  style={{
                    position: 'absolute',
                    backgroundColor: '#000',
                    color: 'white'
                  }}
                  className="overlay-text"
                >
                  {index}
                </span>
                <CropImage
                  width="200px"
                  height="200px"
                  source={item?.source.crop}
                  rectangle={item?.source.rect}
                />
              </div>
            </div>
          ))}
          {isLoading && (
              <div className="d-inline-block" style={{width: 200}}>
                <Spinner size="lg" animation="border" role="status" />
              </div>
          )}
        </div>
      </div>
      {failedCrops.length > 0 && !isLoading && (
        <div className="row">
          <Col className="error-text">
            Unfortunately {failedCrops.length} crop(s) didn't load.{' '}
            <u style={{ cursor: 'pointer' }} onClick={() => reloadCrops()}>
              Try to reload?
            </u>
          </Col>
        </div>
      )}
      <div className="row">
        <Col className="text-center">
          {labels.length > 0 && (
            <SpinnerButton
              variant="info"
              disabled={isLoading || isDisabledLoadMore}
              isLoading={isLoading}
              onClick={showMoreLabels}
              value="Load More"
            />
          )}
        </Col>
      </div>
      {labels.length > 0 && (
        <>
          <CropDetailsModal
            showModal={modalState.detailsCrop}
            modalAction={(value) =>
              setModalState((prev) => ({ ...prev, detailsCrop: value }))
            }
            cropInfo={labels[modalState.detailsCropIndex]}
          />
          <CreateCropJobModal
            showModal={modalState.createCrop}
            setShowModal={(value) =>
              setModalState((prev) => ({ ...prev, createCrop: value }))
            }
            container={container}
            label={label}
          />
        </>
      )}
    </div>
  );
}

export default CreateCropJobs;
